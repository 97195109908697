import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppComponent } from "app/app.component";
import moment from "moment";
import {
  ConfigurationFields,
  ConfigurationModel,
} from "../models/ConfigurationModel";
import { InvoiceModel } from "../models/InvoiceModel";
import { InvoiceDetailService } from "../services/invoice-detail.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { HelpersService } from "app/main/SharedServices/helpers.service";
@Component({
  selector: "app-Invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InvoiceComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("tableRowDetails") tableRowDetails: any;
  @ViewChild("pdfdiv") pdfdiv: ElementRef;

  // public
  public contentHeader: object;
  public today = new Date();
  public nextmonth = moment().add(30, "days").format("MM/DD/YYYY");
  public fromdate = "";
  public InvoiceNumber = "";
  public PO = "";
  public DueDate;
  public ClientMonthlyInvoiceModelTotal = 0;
  public jointTotal = 0;
  public standardsTotal = 0;
  public invoiceTotal = 0;
  public invoiceUuid: any;
  public invoicedata: InvoiceModel = {
    ClientContractPurchaseOrderNumber: "",
    DueDate: "",
    ClientId: "",
    Client: {
      Name: "",
    },
    invoiceUuid: "",
    ClientMonthlyInvoiceLights: [],
  };
  public ConfigurationFields: ConfigurationFields = {
    CompanyAddress: [],
    CompanyPhone: "",
    FederalTaxID: "",
    CompanyLogo: "",
    CompanyEmail: "",
    Header: [],
    footerRight: [],
    footerLeft: [],
  };

  /**
   * Constructor
   *
   * @param {DatatablesService} _datatablesService
   * @param {ActivatedRoute} route
   * @param {ToastrService} _toastrService
   * @param {HelpersService} _helpersService
   * @param {AppComponent} _appComponent
   */
  constructor(
    private _datatablesService: InvoiceDetailService,
    private route: ActivatedRoute,
    private _toastrService: ToastrService,
    private _appComponent: AppComponent,
    private _helpersService: HelpersService,
  ) {
    let that = this;
    that._appComponent._mainpageTitle = "Accounting";
  }
  print() {
    window.print();
  }
  ngOnInit() {
    let that = this;
    setTimeout(() => {
      that.getInvoiceData();
      window.dispatchEvent(new Event("resize"));
    }, 700);

    that.contentHeader = {
      actionButton: false,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Invoices",
          },
          {
            name: "Client Invoices",
            link: "/client-invoices/Invoices",
            isLink: true,
          },
          {
            name: "Invoice",
            isLink: false,
          },
        ],
      },
    };
  }

  getInvoiceData() {
    let that = this;
    that.invoiceUuid = that.route.snapshot.paramMap.get("id");
    that._appComponent.toggeleLoader();
    that._datatablesService.getInvoiceData(that.invoiceUuid).subscribe(
      response => {
        if (response.ClientMonthlyInvoiceModel) {
          that.invoicedata.invoiceUuid = that.invoiceUuid;
          that.invoicedata.ClientMonthlyInvoiceModel =
            response.ClientMonthlyInvoiceModel;
          that.InvoiceNumber = response.ClientMonthlyInvoiceModel.InvoiceId;
          that.PO =
            response.ClientMonthlyInvoiceModel.ClientContractPurchaseOrderNumber;
          that.getClientData(response.ClientMonthlyInvoiceModel.ClientId);
          that.DueDate = response.ClientMonthlyInvoiceModel.DueDate;
          that.fromdate = response.ClientMonthlyInvoiceModel.FromDate;
          that.invoicedata.ClientId =
            response.ClientMonthlyInvoiceModel.ClientId.toString();
          that.invoicedata.InvoiceId =
            response.ClientMonthlyInvoiceModel.InvoiceId;
          that.invoicedata.ClientContractPurchaseOrderNumber =
            response.ClientMonthlyInvoiceModel.ClientContractPurchaseOrderNumber;
          that.invoicedata.SentOnDate =
            response.ClientMonthlyInvoiceModel.SentOnDate;
          that.invoicedata.DueDate = response.ClientMonthlyInvoiceModel.DueDate;
          that.invoicedata.FromDate =
            response.ClientMonthlyInvoiceModel.FromDate;
          that.invoicedata.ToDate = response.ClientMonthlyInvoiceModel.ToDate;
          that.invoicedata.InvoiceTotal =
            response.ClientMonthlyInvoiceModel.InvoiceTotal;
          that.standardsTotal = response.ClientMonthlyInvoiceModel.InvoiceTotal;
          that.invoicedata.SedanServiceFeeQty =
            response.ClientMonthlyInvoiceModel.SedanServiceFeeQty;
          that.invoicedata.SedanBaseFare =
            response.ClientMonthlyInvoiceModel.SedanBaseFare;
          that.invoicedata.SedanServiceFee =
            response.ClientMonthlyInvoiceModel.SedanServiceFee;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.SedanServiceFee,
          );

          that.invoicedata.LargeVehicleServiceFeeQty =
            response.ClientMonthlyInvoiceModel.LargeVehicleServiceFeeQty;
          that.invoicedata.LargeVehicleBaseFare =
            response.ClientMonthlyInvoiceModel.LargeVehicleBaseFare;
          that.invoicedata.LargeVehicleServiceFee =
            response.ClientMonthlyInvoiceModel.LargeVehicleServiceFee;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.LargeVehicleServiceFee,
          );

          that.invoicedata.WheelChairVehicleServiceFeeQty =
            response.ClientMonthlyInvoiceModel.WheelChairVehicleServiceFeeQty;
          that.invoicedata.WheelchairBaseFare =
            response.ClientMonthlyInvoiceModel.WheelchairBaseFare;
          that.invoicedata.WheelChairVehicleServiceFee =
            response.ClientMonthlyInvoiceModel.WheelChairVehicleServiceFee;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.WheelChairVehicleServiceFee,
          );

          that.invoicedata.AdditionalMilesFee =
            response.ClientMonthlyInvoiceModel.AdditionalMilesFee;
          that.invoicedata.MileageIncludedInBaseFare =
            response.ClientMonthlyInvoiceModel.MileageIncludedInBaseFare;
          that.invoicedata.AdditionalMilesFeeQty =
            response.ClientMonthlyInvoiceModel.AdditionalMilesFeeQty;
          that.invoicedata.AdditionalPerMileFee =
            response.ClientMonthlyInvoiceModel.AdditionalPerMileFee;
          that.invoicedata.OldAdditionalPerMileFee =
            response.ClientMonthlyInvoiceModel.OldAdditionalPerMileFee;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.AdditionalMilesFee,
          );

          that.invoicedata.AdditionalStudentsFee =
            response.ClientMonthlyInvoiceModel.AdditionalStudentsFee;
          that.invoicedata.StudentIncludedInBaseFare =
            response.ClientMonthlyInvoiceModel.StudentIncludedInBaseFare;
          that.invoicedata.AdditionalStudentsFeeQty =
            response.ClientMonthlyInvoiceModel.AdditionalStudentsFeeQty;
          that.invoicedata.AdditionalPerStudentFee =
            response.ClientMonthlyInvoiceModel.AdditionalPerStudentFee;
          that.invoicedata.OldAdditionalPerStudentFee =
            response.ClientMonthlyInvoiceModel.OldAdditionalPerStudentFee;

          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.AdditionalStudentsFee,
          );

          that.invoicedata.EquipmentFee =
            response.ClientMonthlyInvoiceModel.EquipmentFee;
          that.invoicedata.EquipmentFeeQty =
            response.ClientMonthlyInvoiceModel.EquipmentFeeQty;
          that.invoicedata.EquipmentFeePerPassenger =
            response.ClientMonthlyInvoiceModel.EquipmentFeePerPassenger;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.EquipmentFee,
          );

          that.invoicedata.AttendantFee =
            response.ClientMonthlyInvoiceModel.AttendantFee;
          that.invoicedata.AttendantFeeQty =
            response.ClientMonthlyInvoiceModel.AttendantFeeQty;
          that.invoicedata.HourlyAideFee =
            response.ClientMonthlyInvoiceModel.HourlyAideFee;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.AttendantFee,
          );

          that.invoicedata.DiscountAmount =
            response.ClientMonthlyInvoiceModel.DiscountAmount;
          that.ClientMonthlyInvoiceModelTotal -= Number(
            that.invoicedata.DiscountAmount,
          );

          that.invoicedata.TotalTollFee =
            response.ClientMonthlyInvoiceModel.TotalTollFee;
          that.invoicedata.TollQty = response.ClientMonthlyInvoiceModel.TollQty;
          that.invoicedata.TollFee = response.ClientMonthlyInvoiceModel.TollFee;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.TotalTollFee,
          );

          that.invoicedata.HardToServeFee =
            response.ClientMonthlyInvoiceModel.HardToServeFee;
          that.invoicedata.HardToServeFeeQty =
            response.ClientMonthlyInvoiceModel.HardToServeFeeQty;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.HardToServeFee,
          );

          that.invoicedata.OtherFee =
            response.ClientMonthlyInvoiceModel.OtherFee;
          that.invoicedata.OtherFeeQty =
            response.ClientMonthlyInvoiceModel.OtherFeeQty;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.OtherFee,
          );

          that.invoicedata.WaitingTimeFee =
            response.ClientMonthlyInvoiceModel.WaitingTimeFee;
          that.invoicedata.WaitingTimeFeeQty =
            response.ClientMonthlyInvoiceModel.WaitingTimeFeeQty;
          that.invoicedata.HourlyWaitingTimeFee =
            response.ClientMonthlyInvoiceModel.HourlyWaitingTimeFee;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.WaitingTimeFee,
          );

          that.invoicedata.IncidentReimbursmentFee =
            response.ClientMonthlyInvoiceModel.IncidentReimbursmentFee;
          that.invoicedata.IncidentReimbursmentFeeQty =
            response.ClientMonthlyInvoiceModel.IncidentReimbursmentFeeQty;
          that.ClientMonthlyInvoiceModelTotal += Number(
            that.invoicedata.IncidentReimbursmentFee,
          );
          that.invoicedata.ClientMonthlyInvoiceLights =
            response.ClientMonthlyInvoiceModel.ClientMonthlyInvoiceLights;
          if (
            response.ClientMonthlyInvoiceModel.ClientMonthlyInvoiceLights &&
            response.ClientMonthlyInvoiceModel.ClientMonthlyInvoiceLights
              .length > 0
          ) {
            let adjustmentData =
              response.ClientMonthlyInvoiceModel.ClientMonthlyInvoiceLights.filter(
                a => a.Type == "Adjustment",
              );
            adjustmentData.forEach(element => {
              that.ClientMonthlyInvoiceModelTotal += Number(
                element.ClientResponsibilityAmount,
              );
            });

            let fuelSurchargesData =
              response.ClientMonthlyInvoiceModel.ClientMonthlyInvoiceLights.filter(
                a => a.Type == "Fuel Surcharges",
              );
            fuelSurchargesData.forEach(element => {
              that.ClientMonthlyInvoiceModelTotal += Number(
                element.ClientResponsibilityAmount,
              );
            });
          }
        }
        if (response.ClientMonthlyInvoicesJointBillingModel) {
          that.InvoiceNumber =
            response.ClientMonthlyInvoicesJointBillingModel.InvoiceId;
          that.invoicedata.InvoiceId =
            response.ClientMonthlyInvoicesJointBillingModel.InvoiceId;
          that.PO =
            response.ClientMonthlyInvoicesJointBillingModel.ClientContractPurchaseOrderNumber;
          that.invoicedata.ClientContractPurchaseOrderNumber =
            response.ClientMonthlyInvoicesJointBillingModel.ClientContractPurchaseOrderNumber;
          that.getClientData(
            response.ClientMonthlyInvoicesJointBillingModel.ClientId,
          );
          that.DueDate =
            response.ClientMonthlyInvoicesJointBillingModel.DueDate;
          that.fromdate =
            response.ClientMonthlyInvoicesJointBillingModel.FromDate;
          that.invoicedata.ClientId =
            response.ClientMonthlyInvoicesJointBillingModel.ClientId.toString();
          that.invoicedata.FromDate =
            response.ClientMonthlyInvoicesJointBillingModel.FromDate;
          that.invoicedata.ToDate =
            response.ClientMonthlyInvoicesJointBillingModel.ToDate;
          that.invoicedata.SentOnDate =
            response.ClientMonthlyInvoicesJointBillingModel.SentOnDate;

          that.invoicedata.ClientMonthlyInvoicesJointBillingModel =
            response.ClientMonthlyInvoicesJointBillingModel;
          that.jointTotal += Number(
            response.ClientMonthlyInvoicesJointBillingModel.SedanServiceFee,
          );
          that.jointTotal += Number(
            response.ClientMonthlyInvoicesJointBillingModel
              .LargeVehicleServiceFee,
          );
          that.jointTotal += Number(
            response.ClientMonthlyInvoicesJointBillingModel
              .WheelChairVehicleServiceFee,
          );

          if (
            !response.ClientMonthlyInvoiceModel &&
            response.ClientMonthlyInvoicesJointBillingModel
              .ClientMonthlyInvoiceLights &&
            response.ClientMonthlyInvoicesJointBillingModel
              .ClientMonthlyInvoiceLights.length > 0
          ) {
            let adjustmentData =
              response.ClientMonthlyInvoicesJointBillingModel.ClientMonthlyInvoiceLights.filter(
                a => a.Type == "Adjustment",
              );
            adjustmentData.forEach(element => {
              that.jointTotal += Number(element.ClientResponsibilityAmount);
            });

            let fuelSurchargesData =
              response.ClientMonthlyInvoicesJointBillingModel.ClientMonthlyInvoiceLights.filter(
                a => a.Type == "Fuel Surcharges",
              );
            fuelSurchargesData.forEach(element => {
              that.jointTotal += Number(element.ClientResponsibilityAmount);
            });
          }

          that.DueDate =
            response.ClientMonthlyInvoicesJointBillingModel.DueDate;
        }

        if (
          response.TransactionsModel &&
          response.TransactionsModel.length > 0
        ) {
          that.invoicedata.TransactionsModel = response.TransactionsModel;
        }

        if (that.DueDate == null) that.DueDate = that.nextmonth;

        that.invoiceTotal = that.standardsTotal + that.jointTotal;
        that._appComponent.toggeleLoader();
        that.getConfiguratioFields();
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
        that._appComponent.toggeleLoader();
      },
    );
  }

  getClientData(ClientId) {
    let that = this;
    that._datatablesService.getClientData(ClientId).subscribe(
      response => {
        that.invoicedata.Client = response;
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
        that._appComponent.toggeleLoader();
      },
    );
  }

  clientAddress1() {
    let that = this;
    if (that.invoicedata.Client.Location)
      return (
        that.invoicedata.Client.Location.Street1 +
        " " +
        (that.invoicedata.Client.Location.Street2 == "" ||
        that.invoicedata.Client.Location.Street2 == null
          ? ""
          : "Suite " + that.invoicedata.Client.Location.Street2)
      );
  }
  clientAddress2() {
    let that = this;
    if (that.invoicedata.Client.Location)
      return (
        that.invoicedata.Client.Location.City +
        ", " +
        that.invoicedata.Client.Location.State +
        " " +
        that.invoicedata.Client.Location.Zipcode
      );
  }
  getFromToDate() {
    let that = this;
    return (
      moment(that.invoicedata.FromDate).format("MM/DD/yyyy") +
      " - " +
      moment(that.invoicedata.ToDate).format("MM/DD/yyyy")
    );
  }
  getConfiguratioFields() {
    let that = this;
    that._datatablesService.loadConfiguration().subscribe(
      response => {
        const configurations: ConfigurationModel[] = response;
        that.fillConfigurationFields(configurations);
      },
      error => {
        if (error.status == 401) {
          that._toastrService.error("UnAuthorized.", "");
          window.location.href = "/pages/authentication/login";
        } else {
          that._toastrService.error(error.error.Message, "");
        }
      },
    );
  }

  fillConfigurationFields(configurations: ConfigurationModel[]) {
    let AddressField = configurations.filter(
      x => x.PropertyName == "CompanyAddress",
    )[0];
    let CompanyPhone = configurations.filter(
      x => x.PropertyName == "CompanyPhone",
    )[0];
    let FederalTaxID = configurations.filter(
      x => x.PropertyName == "CompanyTaxID",
    )[0];
    let CompanyLogo = configurations.filter(
      x => x.PropertyName == "CompanyLogo",
    )[0];
    let CompanyEmail = configurations.filter(
      x => x.PropertyName == "CompanyEmail",
    )[0];
    let that = this;
    if (CompanyLogo.ImageValue?.length != 0) {
      let src = `data:image/png;base64,${CompanyLogo.ImageValue}`;
      that.ConfigurationFields.CompanyLogo = src;
    }
    if (AddressField.PropertyValue?.length != 0) {
      const value =
        AddressField.PropertyValue.toString().split(/(?:\r|\n|\r\n)/g);
      that.ConfigurationFields.CompanyAddress = value;
    }
    if (FederalTaxID.PropertyValue?.length != 0) {
      that.ConfigurationFields.FederalTaxID =
        FederalTaxID.PropertyValue.slice(0, 2) +
        "-" +
        FederalTaxID.PropertyValue.slice(2);
    }

    if (CompanyPhone.PropertyValue?.length != 0) {
      that.ConfigurationFields.CompanyPhone =
        that.getCompanyPhone(CompanyPhone);
    }
    if (CompanyEmail.PropertyValue?.length != 0) {
      that.ConfigurationFields.CompanyEmail = CompanyEmail.PropertyValue;
    }
    const reportConfigurations = configurations.filter(
      x => x.ParentId == null && x.PropertyName == "ClientInvoice",
    )[0];
    const Header = reportConfigurations.SubConfigurations.filter(
      x => x.PropertyType == 8,
    )[0];
    if (Header.PropertyValue?.length != 0) {
      const value = Header.PropertyValue.toString().split(/(?:\r|\n|\r\n)/g);
      that.ConfigurationFields.Header = value;
    }
    let FooterRight = reportConfigurations.SubConfigurations.filter(
      x => x.PropertyType == 6 && x.Direction == 1,
    )[0];
    if (FooterRight.PropertyValue?.length != 0) {
      const value =
        FooterRight.PropertyValue.toString().split(/(?:\r|\n|\r\n)/g);
      that.ConfigurationFields.footerRight = value;
    } else {
      that.ConfigurationFields.footerRight = [];
    }
    let FooterLeft = reportConfigurations.SubConfigurations.filter(
      x => x.PropertyType == 6 && x.Direction == 2,
    )[0];
    if (
      FooterLeft.PropertyValue != null &&
      FooterLeft.PropertyValue.length != 0
    ) {
      const value =
        FooterLeft.PropertyValue.toString().split(/(?:\r|\n|\r\n)/g);
      that.ConfigurationFields.footerLeft = value;
    } else {
      that.ConfigurationFields.footerLeft = [];
    }
  }

  SavePDF(): void {
    let that = this;
    that._appComponent.toggeleLoader();
    html2canvas(that.pdfdiv.nativeElement, { scale: 3 }).then(canvas => {
      const imageGeneratedFromTemplate = canvas.toDataURL("image/png");
      const fileWidth = 200;
      const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
      let PDF = new jsPDF("p", "mm", "a4");
      PDF.addImage(
        imageGeneratedFromTemplate,
        "PNG",
        0,
        5,
        fileWidth,
        generatedImageHeight,
      );
      PDF.html(that.pdfdiv.nativeElement.innerHTML);
      PDF.save(
        `${moment(that.fromdate).format("yyyy_MMMM")}_Invoice_${
          that.InvoiceNumber
        }_PO_${that._helpersService.poNumber(that.PO, 5)}.pdf`,
      );
      that._appComponent.toggeleLoader();
    });
  }
  getCompanyPhone(CompanyPhone) {
    return CompanyPhone.PropertyValue.length >= 3
      ? "(" +
          CompanyPhone.PropertyValue.slice(0, 3) +
          ") " +
          CompanyPhone.PropertyValue.slice(3, 5) +
          CompanyPhone.PropertyValue.slice(5)
      : CompanyPhone.PropertyValue;
  }
}
