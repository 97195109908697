<div
  id="actionBar"
  class="d-flex align-items-end bg-white no-print"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-center">
      <div class="flex-fill page-title">
        <h3 class="text-primary m-0">
          Details for Invoice #{{ invoiceid }} (PO
          {{ PurchaseOrderNumber | poNumber : 5 }}) -
          {{ ClientName }}
        </h3>
      </div>
      <div class="flex-fill text-right">
        <div class="btn-group">
          <div
            ngbDropdown
            [placement]="'bottom-right'"
          >
            <button
              ngbDropdownToggle
              class="btn btn-info btn-page-action"
              type="button"
              id="dropdownMenuButton"
              data-automation-id="dropdown_btn"
              rippleEffect
            >
              <span [data-feather]="'menu'"></span>
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownMenuButton"
            >
              <a
                ngbDropdownItem
                href="javascript:void(0)"
                data-automation-id="show_all_rides"
                (click)="changecheckbox()"
              >
                <input
                  type="checkbox"
                  [checked]="chkBoxSelected"
                  data-automation-id="all_rides_btn"
                  id="showallrides"
                  style="margin-right: 5px; height: 12px; width: 14px"
                />
                Show All Rides</a
              >
              <a
                ngbDropdownItem
                href="javascript:void(0)"
                data-automation-id="show_payment_info"
                (click)="showPayementInformation()"
              >
                <input
                  type="checkbox"
                  [checked]="isColumnHidden"
                  data-automation-id="show_payment_btn"
                  id="showPaymentInfo"
                  style="margin-right: 5px; height: 12px; width: 14px"
                />
                Show Payment Info</a
              >

              <a
                ngbDropdownItem
                href="javascript:window.print()"
              >
                <span
                  [data-feather]="'printer'"
                  data-automation-id="print_btn"
                ></span>
                Print</a
              >
              <a
                ngbDropdownItem
                href="javascript:void(0)"
                (click)="exportToExcel()"
              >
                <span
                  [data-feather]="'download'"
                  data-automation-id="download_btn"
                ></span>
                Download Xlsx</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />

<div
  id="SubactionBar"
  class="bg-white no-print"
>
  <div class="container-fluid">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>
  </div>
</div>

<div class="content-wrapper container-fluid">
  <div class="content-body">
    <div>
      <div class="card">
        <div class="card-body">
          <kendo-grid
            #gridActiveRide
            [data]="rows"
            [sortable]="false"
            [pageSize]="pageSize"
          >
            <ng-template
              kendoGridToolbarTemplate
              NgClass="p-0"
            >
              <div class="col-lg-12 toolbar-control">
                <div class="row">
                  <div class="col-md-6 col-12 pb-0">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="d-flex align-items-center"
                        >Show
                        <select
                          (change)="changelimit()"
                          [(ngModel)]="basicSelectedOption"
                          class="form-control mx-25 form-control-sm ng-pristine ng-valid ng-touched"
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                  <div
                    class="col-md-6 col-12 d-flex justify-content-md-end pb-0 pr-0"
                  >
                    <label class="d-flex align-items-center"
                      >Search<input
                        type="search"
                        placeholder="Search"
                        data-automation-id="serach-input"
                        id="serach-input"
                        class="form-control ml-25 form-control-sm"
                        (keyup)="filterUpdate($event)"
                        (search)="filterUpdate($event)"
                    /></label>
                  </div>
                </div>
              </div>

              <kendo-grid-spacer></kendo-grid-spacer>
            </ng-template>
            <kendo-grid-column
              [width]="60"
              [sortable]="false"
              field="RowOrder"
              title="#"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <span *ngIf="dataItem.RowOrder != 0">
                  {{ dataItem.RowOrder }}
                </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="PlaceName"
              title="School Name"
              [width]="200"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <button
                  *ngIf="
                    dataItem.RideId != 0 &&
                    dataItem.RideId != -1 &&
                    dataItem.RideId != -2 &&
                    dataItem.RideId != -4 &&
                    dataItem.RideId != -5
                  "
                  type="button"
                  rippleEffect
                  class="btn btn-link pl-0 pr-0 text-Paying-Client text-left"
                  ngbPopover="{{ dataItem.PlaceName }}"
                  placement="right"
                  triggers="mouseenter:mouseleave"
                >
                  {{ dataItem.PlaceName }}
                </button>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="PassengerName"
              title="Students"
              [width]="200"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <button
                  *ngIf="
                    dataItem.RideId != 0 &&
                    dataItem.RideId != -1 &&
                    dataItem.RideId != -2 &&
                    dataItem.RideId != -4 &&
                    dataItem.RideId != -5
                  "
                  type="button"
                  rippleEffect
                  class="btn btn-link pl-0 pr-0 text-Paying-Client text-left"
                  ngbPopover="{{ dataItem.PassengerName }}"
                  placement="right"
                  triggers="mouseenter:mouseleave"
                >
                  {{ dataItem.PassengerName }}
                </button>

                <span
                  *ngIf="
                    dataItem.RideId == 0 ||
                    dataItem.RideId == -1 ||
                    dataItem.RideId == -2 ||
                    dataItem.RideId == -4 ||
                    dataItem.RideId == -5
                  "
                >
                  <button
                    *ngIf="dataItem.Description != null"
                    type="button"
                    rippleEffect
                    class="btn btn-link pl-0 pr-0 text-Paying-Client text-left"
                    ngbPopover="({{ dataItem.Description }})"
                    placement="right"
                    triggers="mouseenter:mouseleave"
                  >
                    ({{ dataItem.Description }})
                  </button>
                </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="RideBlueprintId"
              title="Ride Blueprint #"
              [width]="200"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <span
                  *ngIf="
                    dataItem.RideId != 0 &&
                    dataItem.RideId != -1 &&
                    dataItem.RideId != -2 &&
                    dataItem.RideId != -4 &&
                    dataItem.RideId != -5
                  "
                >
                  {{ dataItem.RideBlueprintId }}
                </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="RideId"
              title="Ride #"
              [width]="200"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <span
                  *ngIf="
                    dataItem.RideId != 0 &&
                    dataItem.RideId != -1 &&
                    dataItem.RideId != -2 &&
                    dataItem.RideId != -4 &&
                    dataItem.RideId != -5
                  "
                >
                  {{ dataItem.RideId }}
                </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="DateOfService"
              title="Date (Day)"
              [width]="200"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <span *ngIf="dataItem.DateOfService != '0001-01-01T00:00:00'">
                  {{ dataItem.DateOfService | date : "MM/dd/yyyy" }} ({{
                    dataItem.DateOfService | date : "EEE"
                  }})
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="RideType"
              title="Period"
              [width]="200"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <span *ngIf="dataItem.RideType == 'Outbound'">
                  {{ dataItem.RideType }} (AM)
                </span>
                <span *ngIf="dataItem.RideType == 'Return'">
                  {{ dataItem.RideType }} (PM)
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="LineItemType"
              title="Service Type"
              [width]="200"
              [sortable]="false"
            >
            </kendo-grid-column>
            <kendo-grid-column
              field="EstimatedDistanceInMiles"
              title="Total miles"
              [hidden]="!isColumnHidden"
              [width]="200"
              [sortable]="false"
            >
            </kendo-grid-column>

            <kendo-grid-column
              field="VehicleTypeName"
              title="Vehicle Type"
              [hidden]="!isColumnHidden"
              [width]="200"
              [sortable]="false"
            >
            </kendo-grid-column>

            <kendo-grid-column
              field="PaidAttendantsCount"
              title="Number Of Paid Monitors"
              [hidden]="!isColumnHidden"
              [width]="248"
              [sortable]="false"
            >
            </kendo-grid-column>
            <kendo-grid-column
              field="NumberofStudents"
              title="Number Of Students"
              [hidden]="!isColumnHidden"
              [width]="200"
              [sortable]="false"
            >
            </kendo-grid-column>
            <kendo-grid-column
              field="BaseFare"
              title="Ride Base Fare"
              [hidden]="!isColumnHidden"
              [width]="160"
              [sortable]="false"
            >
            </kendo-grid-column>
            <kendo-grid-column
              field="CalculatedAdditionalMilesFee"
              title="Additional Miles Fee"
              [hidden]="!isColumnHidden"
              [width]="200"
              [sortable]="false"
            >
            </kendo-grid-column>
            <kendo-grid-column
              field="CalculatedEquipmentFee"
              title="Equipments Fee"
              [hidden]="!isColumnHidden"
              [width]="200"
              [sortable]="false"
            >
            </kendo-grid-column>
            <kendo-grid-column
              field="CalculatedTollFee"
              title="Toll Fees"
              [hidden]="!isColumnHidden"
              [width]="200"
              [sortable]="false"
            >
            </kendo-grid-column>
            <kendo-grid-column
              field="AidePaymentAmount"
              title="Calculated Monitors Fee"
              [hidden]="!isColumnHidden"
              [width]="270"
              [sortable]="false"
            >
            </kendo-grid-column>
            <kendo-grid-column
              field="OtherFee"
              title="Calculated Other Fee"
              [hidden]="!isColumnHidden"
              [width]="225"
              [sortable]="false"
            >
            </kendo-grid-column>
            <kendo-grid-column
              field="RideStatus"
              title="Status"
              [width]="200"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <div [ngSwitch]="dataItem.RideStatus">
                  <span *ngSwitchCase="'canceled_in_advance'">
                    Early Cancel
                  </span>
                  <span *ngSwitchCase="'canceled_due_to_holiday'">
                    Holiday
                  </span>
                  <span *ngSwitchCase="'none'"> none </span>
                  <span *ngSwitchCase="'completed'"> Serviced </span>
                  <span *ngSwitchCase="'no_show'"> No Show </span>
                  <span *ngSwitchCase="'canceled_late'"> Late Cancel </span>
                  <span *ngSwitchCase="'in_progress'"> In Progress </span>
                </div>
                <span *ngIf="dataItem.RideId == -1">
                  <strong> TOTAL AMOUNT DUE</strong>
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              field="RideCost"
              title="Price"
              [width]="200"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                <span *ngIf="dataItem.RideId != 0 && dataItem.RideId != -1">
                  $ {{ dataItem.RideCost }}
                </span>
                <span
                  *ngIf="
                    (dataItem.RideId == 0 && dataItem.RideCost != 0) ||
                    dataItem.RideId == -1
                  "
                >
                  <strong> ${{ dataItem.RideCost | number : "1.0-2" }} </strong>
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>

          <div class="col-lg-12 text-center Load-More-section">
            <button
              class="btn btn-light btn-Load-More btn-sm"
              (click)="LoadMore()"
              [disabled]="!disableLoadMore"
              data-automation-id="load_more_btn"
            >
              load more
            </button>

            <div class="page-count ng-star-inserted">
              {{
                basicSelectedOption >= rows.length
                  ? gridView.length
                  : basicSelectedOption
              }}
              of {{ pageSize }} record
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
