<div
  id="actionBar"
  class="d-flex align-items-end bg-white"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-end">
      <div class="flex-fill">
        <div class="sub-title pb-0">Invoices</div>
      </div>
      <div class="flex-fill text-right">
        <div class="btn-group">
          <div
            ngbDropdown
            [placement]="'bottom-right'"
          >
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-info btn-round btn-sm btn-page-action"
              type="button"
              data-automation-id="dropdown_btn"
              rippleEffect
            >
              <i
                class="fa fa-bars"
                aria-hidden="true"
              ></i>
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownSettings"
            >
              <a
                ngbDropdownItem
                href="javascript:window.print();"
                data-automation-id="print_btn"
                ><span
                  [data-feather]="'printer'"
                  [class]="'mr-50'"
                ></span>
                Print</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />

<div class="content-wrapper container-fluid">
  <div class="content-body">
    <div class="card">
      <app-collection-filters
        (search)="getGridData()"
        (clearSearch)="clearAllData()"
        (changeyear)="('')"
        [filters]="FiltersConfig"
      ></app-collection-filters>
      <div class="card-body">
        <kendo-grid
          [kendoGridBinding]="gridView"
          [scrollable]="false"
          [pageSize]="pagesize"
        >
          <ng-template
            kendoGridToolbarTemplate
            NgClass="p-0"
          >
            <div class="col-lg-12 toolbar-control">
              <div class="row">
                <div class="col-md-6 col-12 pb-0">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label class="d-flex align-items-center"
                      >Show
                      <select
                        (change)="changeGridIndex()"
                        [(ngModel)]="gridIndex"
                        data-automation-id="entity_btn"
                        class="form-control mx-25 form-control-sm ng-pristine ng-valid ng-touched"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      entries</label
                    >
                  </div>
                </div>
                <div
                  class="col-md-6 col-12 d-flex justify-content-md-end pb-0 pr-0"
                >
                  <label class="d-flex align-items-center mr-0">
                    Search
                    <input
                      [style.width.px]="165"
                      class="form-control form-control-sm ml-25"
                      placeholder="Search"
                      kendoTextBox
                      (input)="onFilter($event)"
                      data-automation-id="search_input"
                    />
                  </label>
                </div>
              </div>
            </div>

            <kendo-grid-spacer></kendo-grid-spacer>
          </ng-template>

          <kendo-grid-column
            [width]="50"
            field="Index"
            [sortable]="false"
            title="#"
          >
            <ng-template
              kendoGridCellTemplate
              let-rowIndex="rowIndex+1"
            >
              {{ rowIndex }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="Month"
            title="Period"
            [sortable]="false"
            [width]="250"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              {{ dataItem.Month }}
              <span class="hint">
                ({{ dateValue(dataItem.FromDate) }} -
                {{ dateValue(dataItem.ToDate) }})</span
              >
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="Id"
            title="Invoice #"
            [sortable]="false"
            [width]="200"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <a
                data-automation-id="{{ dataItem.Uuid }}"
                class="btn btn-link pl-0 pr-0"
                routerLink="Invoice/{{ dataItem.Uuid }}"
              >
                {{ dataItem.Id }}
              </a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="Number"
            title="PO #"
            [sortable]="false"
            [width]="200"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
              ><span> {{ poNumber(dataItem.Number) }}</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            field="Amount"
            title="Amount"
            [sortable]="false"
            [width]="200"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
              ><span> {{ dataItem.Amount }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="Status"
            title="Status"
            [sortable]="false"
            [width]="200"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <span [class]="getstatus(dataItem, 'class')">
                {{ dataItem.Status }}
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            [sortable]="false"
            title="Action"
            [width]="90"
          >
            <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex"
            >
              <div class="d-flex align-items-center">
                <div
                  ngbDropdown
                  [placement]="'left-top'"
                  container="body"
                  class="m-auto"
                >
                  <a
                    ngbDropdownToggle
                    href="javascript:void(0);"
                    class="hide-arrow"
                    id="dropdownBrowserState"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i
                      data-feather="more-vertical"
                      class="text-primary cursor-pointer mr-50"
                    ></i>
                  </a>
                  <div
                    ngbDropdownMenu
                    class="dropdown-menu-right"
                    aria-labelledby="dropdownBrowserState"
                    disabled
                  >
                    <a
                      href="javascript:void(0)"
                      routerLink="Invoice/{{ dataItem.Uuid }}"
                      ngbDropdownItem
                      class="d-flex align-items-center"
                    >
                      View Invoice
                    </a>
                    <a
                      href="javascript:void(0)"
                      routerLink="/InvoiceDetail/{{ dataItem.Uuid }}"
                      ngbDropdownItem
                      class="d-flex align-items-center"
                    >
                      View Invoice Details
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>

        <div class="col-lg-12 text-center Load-More-section">
          <button
            [disabled]="pagesize >= gridView.length"
            class="btn btn-light btn-Load-More btn-sm"
            (click)="viewMore()"
            data-automation-id="load_more_btn"
          >
            load more
          </button>
          <div class="page-count ng-star-inserted">
            {{ pagesize >= gridData.length ? gridView.length : pagesize }} of
            {{ gridData.length }} record
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
