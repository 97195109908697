export enum PayoutLineItemTypeEnum {
  RideFare = 1,
  RideAttending = 2,
  SignUpBonus = 3,
  Adjustment = 4,
  ReferralBonus = 5,
  WaitingTime = 6,
  EquipmentEimbursement = 7,
  ServiceProviderAdjustment = 8,
}

export enum PayoutLineItemApprovalStatus {
  None = 9,
  Approved = 10,
  NotApproved = 11,
}

export enum RideStatusTypeEnum {
  None = 12,
  InProgress = 13,
  Completed = 14,
  NoShow = 15,
  CanceledDueToHoliday = 16,
  CanceledInAdvance = 17,
  CanceledLate = 18,
  CanceledDueToPandemic = 19,
  Deleted = 20,
  Offered = 21,
  Accepted = 22,
  Declined = 23,
}

export enum RideAttentionStatus {
  DriverNotAssigned = 34,
  DoubleBooked = 35,
  declined = 36,
  none = 37,
}

export enum RideWaypointCargoTypeEnum {
  PickedUp = 41,
  DroppedOff = 42,
  NotDroppedOff = 43,
  SkipDropOff = 44,
  Abscent = 45,
}

export enum RideWaypointTypeEnum {
  None = 46,
  EnRoute = 47,
  Arrived = 48,
  Visited = 49,
  Skipped = 50,
}
