<div
  id="actionBar"
  class="d-flex align-items-end bg-white no-print"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-end">
      <div class="flex-fill page-title">
        <h3 class="text-primary m-0">
          Details for Invoice # {{ InvoiceNumber }}
        </h3>
      </div>
      <div class="flex-fill text-right">
        <div class="btn-group">
          <div
            ngbDropdown
            [placement]="'bottom-right'"
          >
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-info btn-round btn-sm btn-page-action"
              type="button"
              rippleEffect
            >
              <i
                class="fa fa-bars"
                aria-hidden="true"
                data-automation-id="dropdown_btn"
              ></i>
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownSettings"
            >
              <a
                ngbDropdownItem
                (click)="print()"
                data-automation-id="print_btn"
                ><span
                  [data-feather]="'printer'"
                  [class]="'mr-50'"
                ></span>
                Print
              </a>
              <a
                ngbDropdownItem
                routerLink="/InvoiceDetail/{{ invoicedata.invoiceUuid }}"
                data-automation-id="invoice_details"
                ><span
                  [data-feather]="'file-text'"
                  [class]="'mr-50'"
                ></span
                >View Invoice Details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  id="SubactionBar"
  class="bg-white no-print"
>
  <div class="container-fluid"></div>

  <app-content-header
    [contentHeader]="contentHeader"
    [isInvoiceDetail]="true"
  ></app-content-header>
</div>
<div class="container-fluid">
  <div class="content-body">
    <div class="row no-print">
      <div class="col-lg-12"></div>
    </div>

    <section id="home-page">
      <div class="row no-print"></div>
      <div class="content-body">
        <div class="row no-print">
          <div class="col-lg-12"></div>
        </div>

        <section id="home-page">
          <div class="row no-print"></div>
          <div class="content-wrapper p-0">
            <div class="content-body">
              <div class="row">
                <div
                  class="col-xl-9 col-md-12 col-12 order-lg-1 order-2"
                  #pdfdiv
                >
                  <div class="card invoice-preview-card">
                    <div class="card-body invoice-pdding pb-0 mt-2">
                      <!-- Header Starts -->

                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col-lg-12">
                              <img
                                class="mb-1"
                                src="{{ ConfigurationFields.CompanyLogo }}"
                                width="160"
                                alt=""
                              />
                            </div>
                          </div>
                          <ng-container
                            *ngFor="
                              let item of ConfigurationFields.CompanyAddress
                            "
                          >
                            <div class="info-container Ivoice-info">
                              <div class="info-value">{{ item }}</div>
                            </div>
                          </ng-container>
                          <div class="info-container Ivoice-info">
                            <ng-container
                              *ngIf="ConfigurationFields.CompanyPhone?.length"
                            >
                              <div class="info-value">
                                {{ ConfigurationFields.CompanyPhone }}
                              </div>
                            </ng-container>
                          </div>
                          <div
                            class="info-container Ivoice-info"
                            *ngIf="ConfigurationFields.CompanyEmail?.length"
                          >
                            <ng-container>
                              <div class="info-value">
                                {{ ConfigurationFields.CompanyEmail }}
                              </div>
                            </ng-container>
                          </div>
                          <ng-container
                            *ngFor="let item of ConfigurationFields.Header"
                          >
                            <div class="info-container Ivoice-info">
                              <div class="info-value">{{ item }}</div>
                            </div>
                          </ng-container>
                        </div>

                        <div class="col-auto">
                          <div class="row">
                            <div class="col-lg-12 pt-4 pb-1">
                              <h3 class="text-primary m-0 Uppercase">
                                INVOICE
                              </h3>
                            </div>
                          </div>
                          <div class="info-container direction-rtl">
                            <div class="info-title direction-ltr">
                              Invoice #:
                            </div>
                            <div class="info-value">
                              {{ invoicedata.InvoiceId }}
                            </div>
                          </div>
                          <div class="info-container direction-rtl">
                            <div class="info-title direction-ltr">
                              Client #:
                            </div>
                            <div class="info-value">
                              {{ invoicedata.ClientId | poNumber: 5 }}
                            </div>
                          </div>
                          <div class="info-container direction-rtl">
                            <div class="info-title direction-ltr">PO #:</div>
                            <div class="info-value">
                              {{
                                invoicedata.ClientContractPurchaseOrderNumber
                                  | poNumber: 5
                              }}
                            </div>
                          </div>
                          <div class="info-container direction-rtl">
                            <div class="info-title direction-ltr">Date :</div>
                            <div
                              class="info-value"
                              *ngIf="invoicedata.SentOnDate != null"
                            >
                              {{ invoicedata.SentOnDate | date: "MM/dd/yyyy" }}
                            </div>
                            <div
                              class="info-value"
                              *ngIf="invoicedata.SentOnDate == null"
                            >
                              {{ today | date: "MM/dd/yyyy" }}
                            </div>
                          </div>
                        </div>
                        <!-- Header Ends -->
                      </div>
                    </div>

                    <hr class="invoice-spacing" />

                    <!-- Address And Contact Starts -->
                    <div class="card-body invoice-padding pt-0">
                      <div class="d-flex">
                        <div class="flex-fill">
                          <div class="col-xl-12 p-0">
                            <label
                              class="mb-2 d-block text-primary bold title-font"
                              >Invoice To:</label
                            >
                            <span class="mb-25">{{
                              invoicedata.Client.Name
                            }}</span>
                            <p class="card-text mb-25">
                              {{ clientAddress1() }}
                            </p>
                            <p class="card-text mb-25">
                              {{ clientAddress2() }}
                            </p>
                          </div>
                        </div>
                        <div>
                          <label
                            class="mb-2 d-block text-primary bold title-font text-right"
                            >Period Of</label
                          >
                          <table class="w-100">
                            <caption>
                              Period Of
                            </caption>
                            <th></th>
                            <tbody>
                              <tr>
                                <td>
                                  {{ getFromToDate() }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <label
                        class="mt-3 title-font bold"
                        *ngIf="invoicedata.ClientMonthlyInvoiceModel != null"
                        >Standard Rides
                      </label>
                    </div>
                    <!-- Address And Contact Ends -->
                    <!-- Invoice Description Starts -->
                    <div
                      class="table-responsive"
                      *ngIf="invoicedata.ClientMonthlyInvoiceModel != null"
                    >
                      <table class="table">
                        <caption>
                          Invoice Data
                        </caption>
                        <thead>
                          <tr>
                            <th class="ext-uppercase">Description</th>
                            <th class="text-uppercase">Quantity</th>
                            <th class="text-uppercase">Rate</th>
                            <th class="text-uppercase text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.SedanServiceFee != '0' &&
                              invoicedata.SedanServiceFee != null
                            "
                          >
                            <td>Sedan Service Fee</td>
                            <td>{{ invoicedata.SedanServiceFeeQty }}</td>
                            <td>
                              ${{ invoicedata.SedanBaseFare | number: ".2-2" }}
                            </td>
                            <td class="text-right">
                              ${{
                                invoicedata.SedanServiceFee | number: ".2-2"
                              }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.LargeVehicleServiceFee != '0' &&
                              invoicedata.LargeVehicleServiceFee != null
                            "
                          >
                            <td>Large Vehicle Service Fee</td>
                            <td>{{ invoicedata.LargeVehicleServiceFeeQty }}</td>
                            <td>
                              ${{
                                invoicedata.LargeVehicleBaseFare
                                  | number: ".2-2"
                              }}
                            </td>
                            <td class="text-right">
                              ${{
                                invoicedata.LargeVehicleServiceFee
                                  | number: ".2-2"
                              }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.WheelChairVehicleServiceFee != '0' &&
                              invoicedata.WheelChairVehicleServiceFee != null
                            "
                          >
                            <td>Large Vehicle Service Fee</td>
                            <td>
                              {{ invoicedata.WheelChairVehicleServiceFeeQty }}
                            </td>
                            <td>
                              ${{
                                invoicedata.WheelchairBaseFare | number: ".2-2"
                              }}
                            </td>
                            <td class="text-right">
                              ${{
                                invoicedata.WheelChairVehicleServiceFee
                                  | number: ".2-2"
                              }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.AdditionalMilesFee != '0' &&
                              invoicedata.AdditionalMilesFee != null
                            "
                          >
                            <td>
                              Additional Miles Fee (first
                              {{ invoicedata.MileageIncludedInBaseFare }} miles
                              included)
                            </td>
                            <td>{{ invoicedata.AdditionalMilesFeeQty }}</td>

                            <td *ngIf="invoicedata.AdditionalPerMileFee != '0'">
                              ${{
                                invoicedata.AdditionalPerMileFee
                                  | number: ".2-2"
                              }}
                            </td>
                            <td
                              *ngIf="invoicedata.OldAdditionalPerMileFee != '0'"
                            >
                              ${{
                                invoicedata.OldAdditionalPerMileFee
                                  | number: ".2-2"
                              }}
                            </td>
                            <td class="text-right">
                              ${{
                                invoicedata.AdditionalMilesFee | number: ".2-2"
                              }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.AdditionalStudentsFee != '0' &&
                              invoicedata.AdditionalStudentsFee != null
                            "
                          >
                            <td>
                              Additional Students Fee (first
                              {{ invoicedata.StudentIncludedInBaseFare }}
                              students included)
                            </td>
                            <td>{{ invoicedata.AdditionalStudentsFeeQty }}</td>

                            <td
                              *ngIf="invoicedata.AdditionalPerStudentFee != '0'"
                            >
                              ${{
                                invoicedata.AdditionalPerStudentFee
                                  | number: ".2-2"
                              }}
                            </td>
                            <td
                              *ngIf="
                                invoicedata.OldAdditionalPerStudentFee != '0'
                              "
                            >
                              ${{
                                invoicedata.OldAdditionalPerStudentFee
                                  | number: ".2-2"
                              }}
                            </td>
                            <td class="text-right">
                              ${{
                                invoicedata.AdditionalStudentsFee
                                  | number: ".2-2"
                              }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.EquipmentFee != '0' &&
                              invoicedata.EquipmentFee != null
                            "
                          >
                            <td>
                              Equipment Fee (booster seats, safety vests,
                              etc...)
                            </td>
                            <td>{{ invoicedata.EquipmentFeeQty }}</td>
                            <td>
                              ${{
                                invoicedata.EquipmentFeePerPassenger
                                  | number: ".2-2"
                              }}
                            </td>
                            <td class="text-right">
                              ${{ invoicedata.EquipmentFee | number: ".2-2" }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.AttendantFee != '0' &&
                              invoicedata.AttendantFee != null
                            "
                          >
                            <td>Ride Monitor Fee (monitors, nurse, etc...)</td>
                            <td>{{ invoicedata.AttendantFeeQty }}</td>
                            <td>
                              ${{ invoicedata.HourlyAideFee | number: ".2-2" }}
                            </td>
                            <td class="text-right">
                              ${{ invoicedata.AttendantFee | number: ".2-2" }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.DiscountAmount != '0' &&
                              invoicedata.DiscountAmount != null
                            "
                          >
                            <td>Discount</td>
                            <td>-</td>
                            <td>-</td>
                            <td class="text-right">
                              $({{
                                invoicedata.DiscountAmount | number: ".2-2"
                              }})
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.TotalTollFee != '0' &&
                              invoicedata.TotalTollFee != null
                            "
                          >
                            <td>Tolls</td>
                            <td>{{ invoicedata.TollQty }}</td>
                            <td>${{ invoicedata.TollFee | number: ".2-2" }}</td>
                            <td class="text-right">
                              ${{ invoicedata.TotalTollFee | number: ".2-2" }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.HardToServeFee != '0' &&
                              invoicedata.HardToServeFee != null
                            "
                          >
                            <td>Hard-to-Serve Fee</td>
                            <td>{{ invoicedata.HardToServeFeeQty }}</td>
                            <td>-</td>
                            <td class="text-right">
                              ${{ invoicedata.HardToServeFee | number: ".2-2" }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.OtherFee != '0' &&
                              invoicedata.OtherFee != null
                            "
                          >
                            <td>Other Fee</td>
                            <td>{{ invoicedata.OtherFeeQty }}</td>
                            <td>-</td>
                            <td class="text-right">
                              ${{ invoicedata.OtherFee | number: ".2-2" }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.WaitingTimeFee != '0' &&
                              invoicedata.WaitingTimeFee != null
                            "
                          >
                            <td>Additional Waiting Time Fee</td>
                            <td>{{ invoicedata.WaitingTimeFeeQty }}</td>
                            <td>
                              {{
                                invoicedata.HourlyWaitingTimeFee
                                  | number: ".2-2"
                              }}
                            </td>
                            <td class="text-right">
                              ${{ invoicedata.WaitingTimeFee | number: ".2-2" }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.IncidentReimbursmentFee != '0' &&
                              invoicedata.IncidentReimbursmentFee != null
                            "
                          >
                            <td>
                              Incident Reimbursments (pre-authorized by client)
                            </td>
                            <td>
                              {{ invoicedata.IncidentReimbursmentFeeQty }}
                            </td>
                            <td>-</td>
                            <td class="text-right">
                              ${{
                                invoicedata.IncidentReimbursmentFee
                                  | number: ".2-2"
                              }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngFor="
                              let item of invoicedata.ClientMonthlyInvoiceLights
                            "
                          >
                            <td
                              *ngIf="
                                item.Type == 'Adjustment' &&
                                item.Description != null &&
                                item.Description != ''
                              "
                            >
                              Adjustment ({{ item.Description }})
                            </td>
                            <td
                              *ngIf="
                                item.Type == 'Adjustment' &&
                                (item.Description == null ||
                                  item.Description == '')
                              "
                            >
                              Adjustment
                            </td>
                            <td *ngIf="item.Type == 'Adjustment'">-</td>
                            <td *ngIf="item.Type == 'Adjustment'">-</td>
                            <td
                              class="text-right"
                              *ngIf="item.Type == 'Adjustment'"
                            >
                              ${{
                                item.ClientResponsibilityAmount | number: ".2-2"
                              }}
                            </td>

                            <td
                              *ngIf="
                                item.Type == 'Fuel Surcharges' &&
                                item.Description != null &&
                                item.Description != ''
                              "
                            >
                              Fuel Surcharges ({{ item.Description }})
                            </td>
                            <td
                              *ngIf="
                                item.Type == 'Fuel Surcharges' &&
                                (item.Description == null ||
                                  item.Description == '')
                              "
                            >
                              Fuel Surcharges
                            </td>
                            <td *ngIf="item.Type == 'Fuel Surcharges'">-</td>
                            <td *ngIf="item.Type == 'Fuel Surcharges'">-</td>
                            <td
                              class="text-right"
                              *ngIf="item.Type == 'Fuel Surcharges'"
                            >
                              ${{
                                item.ClientResponsibilityAmount | number: ".2-2"
                              }}
                            </td>
                          </tr>

                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.ClientMonthlyInvoiceModel != null
                            "
                          >
                            <td></td>
                            <td></td>
                            <td class="font-weight-bold">
                              <strong>Total</strong>
                            </td>
                            <td
                              class="text-uppercase font-weight-bold text-right"
                            >
                              <strong
                                >${{
                                  ClientMonthlyInvoiceModelTotal
                                    | number: ".2-2"
                                }}</strong
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div
                      class="card-body invoice-padding pt-0"
                      *ngIf="
                        invoicedata.ClientMonthlyInvoicesJointBillingModel !=
                        null
                      "
                    >
                      <label class="mt-3 title-font bold">
                        Non-Standard Rides
                      </label>
                    </div>

                    <div
                      class="table-responsive"
                      *ngIf="
                        invoicedata.ClientMonthlyInvoicesJointBillingModel !=
                        null
                      "
                    >
                      <table class="table w-100">
                        <caption>
                          Joint Billing Sedan Service Fee
                        </caption>
                        <thead>
                          <tr>
                            <th class="ext-uppercase">Description</th>
                            <th class="text-uppercase">Quantity</th>
                            <th class="text-uppercase">Rate</th>
                            <th class="text-uppercase text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.ClientMonthlyInvoicesJointBillingModel
                                .SedanServiceFee != '0'
                            "
                          >
                            <td>Joint Billing Sedan Service Fee</td>
                            <td>
                              {{
                                invoicedata
                                  .ClientMonthlyInvoicesJointBillingModel
                                  .SedanServiceFeeQty
                              }}
                            </td>
                            <td>
                              ${{
                                invoicedata
                                  .ClientMonthlyInvoicesJointBillingModel
                                  .SedanBaseFare | number: ".2-2"
                              }}
                            </td>
                            <td class="text-right">
                              ${{
                                invoicedata
                                  .ClientMonthlyInvoicesJointBillingModel
                                  .SedanServiceFee | number: ".2-2"
                              }}
                            </td>
                          </tr>
                          <ng-container
                            *ngIf="
                              invoicedata.ClientMonthlyInvoiceLights.length ==
                                0 &&
                              invoicedata.ClientMonthlyInvoicesJointBillingModel
                                .ClientMonthlyInvoiceLights &&
                              invoicedata.ClientMonthlyInvoicesJointBillingModel
                                .ClientMonthlyInvoiceLights.length > 0
                            "
                          >
                            <tr
                              class="font-weight-bold"
                              *ngFor="
                                let item of invoicedata
                                  .ClientMonthlyInvoicesJointBillingModel
                                  .ClientMonthlyInvoiceLights
                              "
                            >
                              <td
                                *ngIf="
                                  item.Type == 'Adjustment' &&
                                  item.Description != null &&
                                  item.Description != ''
                                "
                              >
                                Adjustment ({{ item.Description }})
                              </td>
                              <td
                                *ngIf="
                                  item.Type == 'Adjustment' &&
                                  (item.Description == null ||
                                    item.Description == '')
                                "
                              >
                                Adjustment
                              </td>
                              <td *ngIf="item.Type == 'Adjustment'">-</td>
                              <td *ngIf="item.Type == 'Adjustment'">-</td>
                              <td
                                class="text-right"
                                *ngIf="item.Type == 'Adjustment'"
                              >
                                ${{
                                  item.ClientResponsibilityAmount
                                    | number: ".2-2"
                                }}
                              </td>

                              <td
                                *ngIf="
                                  item.Type == 'Fuel Surcharges' &&
                                  item.Description != null &&
                                  item.Description != ''
                                "
                              >
                                Fuel Surcharges ({{ item.Description }})
                              </td>
                              <td
                                *ngIf="
                                  item.Type == 'Fuel Surcharges' &&
                                  (item.Description == null ||
                                    item.Description == '')
                                "
                              >
                                Fuel Surcharges
                              </td>
                              <td *ngIf="item.Type == 'Fuel Surcharges'">-</td>
                              <td *ngIf="item.Type == 'Fuel Surcharges'">-</td>
                              <td
                                class="text-right"
                                *ngIf="item.Type == 'Fuel Surcharges'"
                              >
                                ${{
                                  item.ClientResponsibilityAmount
                                    | number: ".2-2"
                                }}
                              </td>
                            </tr>
                          </ng-container>
                          <tr
                            class="font-weight-bold"
                            *ngIf="
                              invoicedata.ClientMonthlyInvoicesJointBillingModel !=
                              null
                            "
                          >
                            <td></td>
                            <td></td>
                            <td class="text-uppercase font-weight-bold">
                              <strong>Total</strong>
                            </td>
                            <td
                              class="text-uppercase font-weight-bold text-right"
                            >
                              <strong
                                >${{ jointTotal | number: ".2-2" }}</strong
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="table-responsive">
                      <table class="table">
                        <caption>
                          TOTAL AMOUNT
                        </caption>
                        <thead>
                          <tr>
                            <th class="text-uppercase">DUE DATE</th>
                            <th class="text-uppercase text-right">
                              TOTAL AMOUNT DUE
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="font-weight-bold">
                            <td>
                              <b>
                                {{ DueDate | date: "MM/dd/yyyy" }}
                              </b>
                            </td>
                            <td class="text-right">
                              <b> ${{ invoiceTotal | number: ".2-2" }} </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <br />
                    <br />
                    <style>
                      .Transaction tr th {
                        background-color: transparent !important;
                      }

                      .Transaction tr > th {
                        font-size: 11px !important;
                      }
                    </style>
                    <!--Transaction-->
                    <div
                      class="card-body invoice-padding pt-0"
                      *ngIf="invoicedata.TransactionsModel != null"
                    >
                      <label class="mt-3 title-font bold"> Transactions </label>
                    </div>
                    <div
                      class="Transaction table-responsive"
                      *ngIf="invoicedata.TransactionsModel != null"
                    >
                      <table class="table w-100">
                        <caption>
                          PAYMENT
                        </caption>
                        <thead>
                          <tr>
                            <th class="ext-uppercase">
                              PAYMENT # / TRANSACTION ID
                            </th>
                            <th class="text-uppercase">PAYMENT MODE</th>
                            <th class="text-uppercase">DATE</th>
                            <th class="text-uppercase text-right">AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let item of invoicedata.TransactionsModel"
                            class="font-weight-bold"
                          >
                            <td>{{ item.TransactionId }}</td>
                            <td>{{ item.PaymentMode }}</td>
                            <td>
                              {{ item.AccrualDate | date: "MM/dd/yyyy" }}
                            </td>
                            <td class="text-right">
                              ${{ item.PaidAmount | number: ".2-2" }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr class="invoice-spacing" />

                    <!-- Invoice Note Starts -->
                    <div class="card-body invoice-padding pt-0">
                      <div class="row">
                        <div class="col-12">
                          <span class="font-weight-bold">Note: </span>
                          <ul>
                            <li>
                              <div>
                                All checks are payable to
                                <span class="bold">
                                  ADROIT Advanced Technologies, Inc.
                                </span>
                              </div>
                            </li>
                            <li>
                              <div>
                                ACH payments are available for your convenience.
                                Contact us for details.
                              </div>
                            </li>
                            <li>
                              <div>
                                Payments not received by the due date will
                                result in a late fee being added to an upcoming
                                month’s invoice.
                              </div>
                            </li>
                            <li>
                              <div>
                                Please submit any disputed charges before the
                                end of the next invoice period.
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="row">
                        <div class="card-body invoice-pdding pb-0 mt-2">
                          <div class="row">
                            <div class="col">
                              <ng-container
                                *ngFor="
                                  let item of ConfigurationFields.footerLeft
                                "
                              >
                                <div class="info-container">
                                  <div class="info-value">
                                    <span class="bold">{{ item }}</span>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                            <div class="col-auto">
                              <ng-container
                                *ngFor="
                                  let item of ConfigurationFields.footerRight
                                "
                              >
                                <div class="info-container">
                                  <div class="info-value">
                                    <span class="bold">{{ item }}</span>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Invoice Note Ends -->
                  </div>
                </div>
                <div
                  class="col-xl-3 col-md-12 col-12 mt-md-0 mt-2 order-lg-2 order-1 no-print"
                >
                  <div class="card">
                    <div class="card-body">
                      <button
                        class="btn btn-secondary btn-block btn-download-invoice mb-75"
                        data-automation-id="download_btn"
                        (click)="SavePDF()"
                        rippleEffect
                      >
                        Download as PDF
                      </button>
                      <a
                        class="btn btn-light btn-block mb-75"
                        href="javascript:window.print();"
                        data-automation-id="print_invoice_btn"
                        rippleEffect
                      >
                        Print
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Basic Alerts end -->
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
