export const environment = {
    production: false,
    hmr: false,
    type: "Production",
    apiUrl: "https://api.goadroit.com/v1",
    webUrl: "https://provider.goadroit.com/",
    rideWaypointsEtaRootCollection: "jobs",
    client_id: '783896173032-91afo3riksqg88mm8ffh2cjns3f0rtl6.apps.googleusercontent.com',
    microsoft_client_id: 'c0ae9cee-d738-4283-b266-b173c6946f4e',
    redirect_uri: 'https://api.goadroit.com',
    logout_redirect_uri: 'https://api.goadroit.com/logout',
    clientUrl: "https://app.goadroit.com/",
};
