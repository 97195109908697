import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";

import { ActivatedRoute } from "@angular/router";
import { AppComponent } from "app/app.component";
import { ToastrService } from "ngx-toastr";
import moment from "moment";
import { InvoiceDetailService } from "../services/invoice-detail.service";
import { ExcelService } from "app/main/SharedServices/export.service";
import { HelpersService } from "app/main/SharedServices/helpers.service";
import { RideStatusTypeEnum } from "../../../enums/lookups/LookupEnum";

@Component({
  selector: "app-InvoiceDetail",
  templateUrl: "./invoice-detail.component.html",
  styleUrls: ["./invoice-detail.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class InvoiceDetailComponent implements OnInit {
  public isFiltersEnable = false;
  private tempData = [];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("tableRowDetails") tableRowDetails: any;
  @ViewChild("pdfdiv") pdfdiv: ElementRef;

  // public
  public contentHeader: object;
  public rows: any = [];
  public originalData: any = [];
  public selected = [];
  public ClientInvoicesReportRows: any;
  public basicSelectedOption: number = 500;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public editingName = {};
  public editingStatus = {};
  public editingAge = {};
  public editingSalary = {};
  public chkBoxSelected = true;
  public disableLoadMore: boolean = false;
  public SelectionType = SelectionType;
  public exportCSVData;
  public invoiceid;
  public PurchaseOrderNumber = "";
  public ClientName = "";
  public fileName;
  public invoiceUuid: any;
  public isColumnHidden = false;
  public pageSize: number = 0;
  public gridData: any = "";
  public gridView: any = "";

  /**
   * Constructor
   *
   * @param {_invoiceDetailService} InvoiceDetailService
   * @param {route} ActivatedRoute
   * @param {_toastrService} ToastrService
   * @param {_appComponent} AppComponent
   * @param {_helperService} HelpersService
   * @param {_excelService} ExcelService
   */
  constructor(
    private _invoiceDetailService: InvoiceDetailService,
    private route: ActivatedRoute,
    private _appComponent: AppComponent,
    private _toastrService: ToastrService,
    private _helperService: HelpersService,
    private _excelService: ExcelService,
  ) {
    this._appComponent._mainpageTitle = "Accounting";
  }

  ngOnInit() {
    setTimeout(() => {
      this.getGridData(false);

      window.dispatchEvent(new Event("resize"));
    }, 700);
    this.invoiceUuid = this.route.snapshot.paramMap.get("id");

    this.contentHeader = {
      headerTitle: "",
      actionButton: false,
      breadcrumb: {
        type: "",
        links: [
          {
            name: "Client Invoices",
          },
          {
            name: "Invoice",
            link:
              "/client-invoices/Invoices/Invoice/" +
              this.invoiceUuid.toString(),
            isLink: true,
          },
          {
            name: "Invoice Details ",
            isLink: false,
          },
        ],
      },
    };
  }

  /**
   * Search (filter)
   *
   * @param event
   */
  filterUpdate(event) {
    const val = (event.target as HTMLInputElement).value.toLowerCase();
    // filter our data
    let firstarray: any[] = [];
    firstarray.push(
      this.tempData.filter(x => x.PlaceName?.toLowerCase().includes(val)),
    );
    firstarray.push(
      this.tempData.filter(
        x => x.PassengerName?.toLowerCase().toString().includes(val),
      ),
    );
    firstarray.push(
      this.tempData.filter(x => x.RideId.toString().includes(val)),
    );
    firstarray.push(
      this.tempData.filter(x =>
        this.getPeriod(x).toLowerCase().toString().includes(val),
      ),
    );
    firstarray.push(
      this.tempData.filter(x =>
        this.getServiceType(x).toLowerCase().includes(val),
      ),
    );
    firstarray.push(
      this.tempData.filter(x =>
        this.getPrice(x).toLowerCase().toString().includes(val),
      ),
    );
    firstarray.push(
      this.tempData.filter(x =>
        this.getStatusForFilter(x).toLowerCase().toString().includes(val),
      ),
    );

    firstarray.push(
      this.tempData.filter(x =>
        this.getDateDay(x).toLowerCase().toString().includes(val),
      ),
    );

    firstarray.push(
      this.tempData.filter(x =>
        this.getRideBlueprintId(x).toLowerCase().includes(val),
      ),
    );
    if (this.isColumnHidden) {
      firstarray.push(
        this.tempData.filter(
          x =>
            x.EstimatedDistanceInMiles?.toLowerCase().toString().includes(val),
        ),
      );
      firstarray.push(
        this.tempData.filter(x =>
          this.getVehicleType(x).toLowerCase().toString().includes(val),
        ),
      );
      firstarray.push(
        this.tempData.filter(x =>
          this.getNumberOfPaidMonitors(x)
            .toLowerCase()
            .toString()
            .includes(val),
        ),
      );

      firstarray.push(
        this.tempData.filter(x =>
          this.getNumberOfStudents(x).toLowerCase().toString().includes(val),
        ),
      );
      firstarray.push(
        this.tempData.filter(x =>
          this.getRideBaseFare(x).toLowerCase().toString().includes(val),
        ),
      );
      firstarray.push(
        this.tempData.filter(x =>
          this.getAdditionalMilesFee(x).toLowerCase().toString().includes(val),
        ),
      );
      firstarray.push(
        this.tempData.filter(x =>
          this.getEquipmentsFee(x).toLowerCase().toString().includes(val),
        ),
      );
      firstarray.push(
        this.tempData.filter(x =>
          this.getTollFees(x).toLowerCase().toString().includes(val),
        ),
      );
      firstarray.push(
        this.tempData.filter(x =>
          this.getCalculatedMonitorsFee(x)
            .toLowerCase()
            .toString()
            .includes(val),
        ),
      );
      firstarray.push(
        this.tempData.filter(x =>
          this.getCalculatedOtherFee(x).toLowerCase().toString().includes(val),
        ),
      );
    }

    let secondarray = !this.isColumnHidden
      ? firstarray[0].concat(
          firstarray[1],
          firstarray[2],
          firstarray[3],
          firstarray[4],
          firstarray[5],
          firstarray[6],
          firstarray[7],
          firstarray[8],
        )
      : firstarray[0].concat(
          firstarray[1],
          firstarray[2],
          firstarray[3],
          firstarray[4],
          firstarray[5],
          firstarray[6],
          firstarray[7],
          firstarray[8],
          firstarray[9],
          firstarray[10],
          firstarray[11],
          firstarray[12],
          firstarray[13],
          firstarray[14],
          firstarray[15],
          firstarray[16],
          firstarray[17],
          firstarray[18],
        );
    // update the rows

    if (val == "") this.rows = this.tempData;
    else {
      let arrraytodisplay = [];
      secondarray.forEach(function (item) {
        let i = arrraytodisplay.filter(x => x.LineItemId == item.LineItemId);
        if (i.length == 0) {
          arrraytodisplay.push(item);
        }
      });

      this.rows = arrraytodisplay;
    }
  }

  changecheckbox() {
    this.chkBoxSelected = !this.chkBoxSelected;
    this.getGridData(false);
  }

  showPayementInformation() {
    this.getGridData(true);
  }

  paginate(page_size) {
    let that = this;
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    that.rows = [...that.originalData.slice(0, 1 * parseInt(page_size))];
  }

  getGridData(shoqPayment) {
    $("#serach-input").val("");
    let that = this;
    that.invoiceUuid = this.route.snapshot.paramMap.get("id");

    this._appComponent.toggeleLoader();
    this._invoiceDetailService
      .getGridData(that.invoiceUuid, this.chkBoxSelected)
      .subscribe(
        response => {
          that.ClientInvoicesReportRows = response.InvoiceDetails;
          that.ClientName = response.ClientName;
          that.invoiceid = response.InvoiceId;
          that.PurchaseOrderNumber = response.PurchaseOrderNumber;

          that.rows = response.InvoiceDetails;
          that.originalData = [...that.rows];
          that.gridView = response.InvoiceDetails;
          that.gridData = response.InvoiceDetails.length;
          this.pageSize = response.InvoiceDetails.length;
          let content = response.InvoiceDetails.filter(
            x => x.LineItemType == "Ride Fare",
          )[0].DateOfService;
          that.fileName = `${moment(content).format("yyyy_MMMM")}_Invoice_${
            that.invoiceid
          }_PO_${that._helperService.poNumber(
            that.PurchaseOrderNumber,
            5,
          )}_Details`;
          //#region  Gird Data
          that.tempData = response.InvoiceDetails;
          if (shoqPayment) {
            that.isColumnHidden = !that.isColumnHidden;
          }
          //#endregion
          that._appComponent.toggeleLoader(); //loader
        },
        error => {
          if (error.status == 401) {
            that._toastrService.error("UnAuthorized.", "");
            window.location.href = "/pages/authentication/login";
          } else {
            that._toastrService.error(error.error.Message, "");
          }
          that._appComponent.toggeleLoader();
        },
      );
  }

  changelimit() {
    let that = this;
    if (that.gridData > that.basicSelectedOption) {
      that.paginate(that.basicSelectedOption);
      that.gridView.length = that.basicSelectedOption;
      that.disableLoadMore = true;
    } else {
      that.disableLoadMore = true;
    }
  }

  LoadMore() {
    this.basicSelectedOption =
      parseInt(this.basicSelectedOption.toString()) + 25;
    if (this.basicSelectedOption < this.pageSize) {
      this.gridView.length = this.basicSelectedOption;
      this.paginate(this.basicSelectedOption);
    }
    if (this.basicSelectedOption > this.pageSize) {
      this.basicSelectedOption =
        parseInt(this.basicSelectedOption.toString()) - 25;
      this.basicSelectedOption =
        this.pageSize - parseInt(this.basicSelectedOption.toString());
      this.basicSelectedOption = this.pageSize;
      this.gridView.length = this.pageSize;
      this.rows.length = this.pageSize;
      this.paginate(this.pageSize);
      this.disableLoadMore = false;
    }
  }

  getId(row) {
    if (row.AttentionStatus == 1024) {
      // multi joint billing
      return row.RideId == 0 ||
        row.RideId == -1 ||
        row.RideId == -4 ||
        row.RideId == -5
        ? ""
        : row.RowOrder +
            " " +
            ("<span>" +
              '<div class="ml-1 status-badge bg-warning hover-pointer" data-html="true" data-container="body" data-toggle="popover-hover" data-placement="left" data-content="' +
              row.AttentionStatusText +
              '" data-original-title="Attention"></div>' +
              "</span>");
    } else {
      let status = "";
      if (row.AttentionStatus > 0) {
        status =
          '<div class="ml-1 status-badge bg-danger hover-pointer" data-html="true" data-container="body" data-toggle="popover-hover" data-placement="left" data-content="' +
          row.AttentionStatusText +
          '" data-original-title="Attention"></div>';
      } else if (this.checkLineITem(row)) {
        status =
          '<div class="ml-1 status-badge bg-danger hover-pointer" data-html="true" data-container="body" data-toggle="popover-hover" data-placement="left" data-content="One or more invoice line items with none approval status." data-original-title="Attention"></div>';
      }

      return row.RideId == 0 ||
        row.RideId == -1 ||
        row.RideId == -4 ||
        row.RideId == -5
        ? ""
        : row.RowOrder + " " + ("<span>" + status + "</span>");
    }
  }
  checkLineITem(row: any): boolean {
    return (
      (row.LineItemType == "Waiting Time" ||
        row.LineItemType == "Adjustment") &&
      row.ApprovalStatus != "approved"
    );
  }
  getRideId(row) {
    let rideId = row.RideId.toString();
    if (row.RideId == -2) {
      rideId = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : rideId;
  }

  getVehicleType(row) {
    let VehicleTypeName = row.VehicleTypeName.toString();
    if (row.RideId == -2) {
      VehicleTypeName = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : VehicleTypeName;
  }

  getNumberOfPaidMonitors(row) {
    let PaidAttendantsCount = row.PaidAttendantsCount.toString();
    if (row.RideId == -2) {
      PaidAttendantsCount = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : PaidAttendantsCount;
  }

  getNumberOfStudents(row) {
    let NumberofStudents = row.NumberofStudents.toString();
    if (row.RideId == -2) {
      NumberofStudents = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : NumberofStudents;
  }

  getRideBlueprintId(row) {
    let RideBlueprintId = row.RideBlueprintId.toString();
    if (row.RideId == -2) {
      RideBlueprintId = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : RideBlueprintId;
  }

  getRideBaseFare(row) {
    let BaseFare = row.BaseFare.toString();
    if (row.RideId == -2) {
      BaseFare = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : BaseFare;
  }

  getAdditionalMilesFee(row) {
    let CalculatedAdditionalMilesFee =
      row.CalculatedAdditionalMilesFee.toString();
    if (row.RideId == -2) {
      CalculatedAdditionalMilesFee = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : CalculatedAdditionalMilesFee;
  }

  getEquipmentsFee(row) {
    let CalculatedEquipmentFee = row.CalculatedEquipmentFee.toString();
    if (row.RideId == -2) {
      CalculatedEquipmentFee = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : CalculatedEquipmentFee;
  }

  getTollFees(row) {
    let CalculatedTollFee = row.CalculatedTollFee.toString();
    if (row.RideId == -2) {
      CalculatedTollFee = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : CalculatedTollFee;
  }

  getCalculatedMonitorsFee(row) {
    let AidePaymentAmount = row.AidePaymentAmount.toString();
    if (row.RideId == -2) {
      AidePaymentAmount = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : AidePaymentAmount;
  }

  getCalculatedOtherFee(row) {
    let OtherFee = row.OtherFee.toString();
    if (row.RideId == -2) {
      OtherFee = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : OtherFee;
  }

  getDateDay(row) {
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : moment(row.DateOfService).format("MM/DD/YYYY") +
          " (" +
          moment(row.DateOfService).format("ddd") +
          ")";
  }

  getPeriod(row) {
    let RidePeriod =
      row.RideType + " (" + (row.RidePeriod == "Morning" ? "AM" : "PM") + ")";
    if (row.RideId == -2) {
      RidePeriod = "-";
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : RidePeriod;
  }

  getServiceType(row) {
    let LineItemType = this._helperService.toTitleCase(row.LineItemType);
    if (row.RideId == -2) {
      LineItemType = this._helperService.toTitleCase(row.LineItemType);
    }
    return row.RideId == 0 ||
      row.RideId == -1 ||
      row.RideId == -4 ||
      row.RideId == -5
      ? ""
      : LineItemType;
  }

  getStatus(row) {
    let rideStatus = row.RideStatus;
    if (row.RideId == -1) {
      rideStatus =
        '<span style="width:160px"><strong>TOTAL AMOUNT DUE</strong></span>';
    } else if (row.RideId == 0 || row.RideId == -2) {
      rideStatus = "";
    } else if (row.RideStatusId == RideStatusTypeEnum.Completed) {
      rideStatus = "Serviced";
    } else if (row.RideStatusId == RideStatusTypeEnum.NoShow) {
      rideStatus = "No Show";
    } else if (row.RideStatusId == RideStatusTypeEnum.CanceledLate) {
      rideStatus = "Late Cancel";
    } else if (row.RideStatusId == RideStatusTypeEnum.CanceledInAdvance) {
      rideStatus = "Early Cancel";
    } else if (row.RideStatusId == RideStatusTypeEnum.CanceledDueToHoliday) {
      rideStatus = "Holiday";
    } else if (
      (row.LineItemType == "Waiting Time" ||
        row.LineItemType == "Adjustment") &&
      row.ApprovalStatus == "not_approved"
    ) {
      rideStatus = "not approved";
    } else if (
      !(row.LineItemType == "Waiting Time" || row.LineItemType == "Adjustment")
    ) {
      rideStatus = row.ApprovalStatus;
    }
    return rideStatus;
  }

  getPrice(row) {
    let RideCost = "";
    if (row.RideId == 0 || row.RideId == -1) {
      RideCost =
        "<span><strong>" +
        "$" +
        this._helperService.formatNumberThousandSeparator(
          parseFloat(row.RideCost).toFixed(2),
        ) +
        "</strong></span>";
    } else if (!(row.RideId == 0 || row.RideId == -1)) {
      RideCost =
        "$" +
        this._helperService.formatNumberThousandSeparator(
          parseFloat(row.RideCost).toFixed(2),
        );
    }
    return row.RideCost == 0 && row.RideId == 0 ? "" : RideCost;
  }

  getPriceForFilter(row) {
    let PriceForFilter = this._helperService.formatNumberThousandSeparator(
      parseFloat(row.RideCost).toFixed(2),
    );

    return row.RideCost == 0 && row.RideId == 0 ? "" : PriceForFilter;
  }

  getStatusForFilter(row) {
    let statusForFilter = row.RideStatus;
    if (row.RideId == -1) {
      statusForFilter = "TOTAL AMOUNT DUE";
    } else if (
      row.RideId == 0 ||
      row.RideId == -2 ||
      row.RideId == null ||
      row.RideStatusId == null
    ) {
      statusForFilter = "";
    } else if (row.RideStatusId == RideStatusTypeEnum.Completed) {
      statusForFilter = "Serviced";
    } else if (row.RideStatusId == RideStatusTypeEnum.NoShow) {
      statusForFilter = "No Show";
    } else if (row.RideStatusId == RideStatusTypeEnum.CanceledLate) {
      statusForFilter = "Late Cancel";
    } else if (row.RideStatusId == RideStatusTypeEnum.CanceledInAdvance) {
      statusForFilter = "Early Cancel";
    } else if (row.RideStatusId == RideStatusTypeEnum.CanceledDueToHoliday) {
      statusForFilter = "Holiday";
    } else if (
      (row.LineItemType == "Waiting Time" ||
        row.LineItemType == "Adjustment") &&
      row.ApprovalStatus == "not_approved"
    ) {
      statusForFilter = "not approved";
    } else if (
      !(row.LineItemType == "Waiting Time" || row.LineItemType == "Adjustment")
    ) {
      statusForFilter = row.ApprovalStatus;
    }

    return statusForFilter;
  }

  getStatusForExcel(row) {
    let rideStatusExcel = row.RideStatus;
    if (row.RideId == -1) {
      rideStatusExcel = "TOTAL AMOUNT DUE";
    } else if (row.RideId == 0 || row.RideId == -2) {
      rideStatusExcel = "";
    } else if (row.RideStatusId == RideStatusTypeEnum.Completed) {
      rideStatusExcel = "Serviced";
    } else if (row.RideStatusId == RideStatusTypeEnum.NoShow) {
      rideStatusExcel = "No Show";
    } else if (row.RideStatusId == RideStatusTypeEnum.CanceledLate) {
      rideStatusExcel = "Late Cancel";
    } else if (row.RideStatusId == RideStatusTypeEnum.CanceledInAdvance) {
      rideStatusExcel = "Early Cancel";
    } else if (row.RideStatusId == RideStatusTypeEnum.CanceledDueToHoliday) {
      rideStatusExcel = "Holiday";
    } else if (
      (row.LineItemType == "Waiting Time" ||
        row.LineItemType == "Adjustment") &&
      row.ApprovalStatus == "not_approved"
    ) {
      rideStatusExcel = "not approved";
    } else if (
      !(row.LineItemType == "Waiting Time" || row.LineItemType == "Adjustment")
    ) {
      rideStatusExcel = row.ApprovalStatus;
    }
    return rideStatusExcel;
  }

  extraExcelColumn(row) {
    return {};
  }

  exportToExcel() {
    let exportarray = [];
    let that = this;
    this.ClientInvoicesReportRows.forEach(element => {
      exportarray = that.prepareExcelElement(element, exportarray);
    });
    let dataForExcel = [];
    exportarray.forEach((row: any) => {
      dataForExcel.push(Object.values(row));
    });
    if (exportarray.length > 0) {
      let reportData = {
        title: `Invoice #${this.invoiceid} Details`,
        titlelength: "G",
        subtitle: "",
        subtitlelength: "G",
        data: dataForExcel,
        filename: this.fileName,
        headers: Object.keys(exportarray[0]),
      };
      this._excelService.exportExcel(reportData);
    }
  }
  prepareExcelElement(element, exportarray: any[]): any[] {
    let that = this;
    let record: any;
    if (this.isColumnHidden) {
      record = {
        "#": that.getId(element).replace(/\D/g, ""),
        "School Name": that.checkRideId(element) ? element.PlaceName : "",
        Students: that.checkRideId(element)
          ? element.PassengerName
          : element.Description ?? "",
        "Ride #": this.getRideId(element),
        "Date (Day)": this.getDateDay(element),
        Period: this.getPeriod(element),
        "Service Type": this.getServiceType(element),
        "Total miles": element.EstimatedDistanceInMiles,
        "Vehicle Type": this.getVehicleType(element),
        "Number Of Paid Monitors": this.getNumberOfPaidMonitors(element),
        "Number Of Students": this.getNumberOfStudents(element),
        "Ride Base Fare": this.getRideBaseFare(element),
        "Additional Miles Fee": this.getAdditionalMilesFee(element),
        "Equipments Fee": this.getEquipmentsFee(element),
        "Toll Fees": this.getTollFees(element),
        "Calculated Monitors Fee": this.getCalculatedMonitorsFee(element),
        "Calculated Other Fee": this.getCalculatedOtherFee(element),
        Status: this.getStatusForExcel(element),
        Price:
          this.getPriceForFilter(element) != ""
            ? "$" + this.getPriceForFilter(element)
            : "",
      };
    } else {
      record = {
        "#": that.getId(element).replace(/\D/g, ""),
        "School Name ": that.checkRideId(element)
          ? element.PlaceName
          : element.Description ?? "",
        Students: element.PassengerName,
        "Ride Blueprint #": this.getRideBlueprintId(element),
        "Ride #": this.getRideId(element),
        "Date (Day)": this.getDateDay(element),
        Period: this.getPeriod(element),
        "Service Type": this.getServiceType(element),
        Status: this.getStatusForExcel(element),
        Price:
          this.getPriceForFilter(element) != ""
            ? "$" + this.getPriceForFilter(element)
            : "",
      };
    }

    exportarray.push(record);
    return exportarray;
  }
  checkRideId(element: any): boolean {
    return (
      element.RideId != 0 &&
      element.RideId != -1 &&
      element.RideId != -2 &&
      element.RideId != -4 &&
      element.RideId != -5
    );
  }
}
