<ng-template
  #AddNewServiceCancelationModal
  let-modal
>
  <div class="modal-header">
    <h5
      class="modal-title"
      id="myModalLabel160"
    >
      {{ PageTitle }}
    </h5>

    <button
      type="button"
      class="close"
      (click)="close(modal)"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="modal-body"
    tabindex="0"
    style="overflow-y: hidden; overflow-x: hidden; padding: 14px"
    ngbAutofocus
  >
    <div class="row">
      <div class="row">
        <div class="col-lg-12">
          <h4 class="text-black">
            <strong style="margin: 14px">Basic Details</strong>
          </h4>
        </div>
        <div class="col-12 pb-4">
          <form
            [formGroup]="newServiceCancelationForm"
            (ngSubmit)="onSubmit(modal)"
          >
            <div
              class="row"
              style="display: flex; padding-left: 15px; padding-right: 15px"
            >
              <div
                *ngIf="Config.Filters[0].filterVisability"
                class="col-lg-4"
                Id="selector"
              >
                <!-- Basic Select with Search -->
                <div style="margin-bottom: 5px">Recurrence Frequency</div>
                <ng-select
                  appendTo="body"
                  [(ngModel)]="Config.Filters[0].filterValue"
                  [clearable]="Config.Filters[0].filterClear"
                  [disabled]="Config.Filters[0].filterDisabled"
                  [disabledd]="PermessionValue"
                  [multiple]="Config.filterMultiple"
                  [placeholder]="Config.Filters[0].filterPlaceholder"
                  formControlName="ServiceCancelationRecurrenceFrequency"
                  (change)="typeChanged()"
                  (click)="typeChanged()"
                >
                  <ng-option
                    *ngFor="let item of Config.Filters[0].filterValues"
                    [value]="item.id"
                    >{{ item.name }}</ng-option
                  >
                </ng-select>
                <!--/ Basic Select with Search -->
              </div>

              <div
                class="col-lg-4"
                *ngIf="Config.Filters[0].filterValue == 'Once'"
              >
                <app-date-time-picker
                  name="{{ isAdd ? 'Effective Date' : 'From Date' }}"
                  [disabledDates]="disabledDates"
                  placeholder="00/00/0000"
                  formControlName="ServiceCancelationFromDate"
                  (ngModelChange)="ChangeStartValue()"
                  [initialValue]="initialDate"
                  [disable]="!disabledService"
                ></app-date-time-picker>
              </div>
              <div
                class="col-lg-4"
                *ngIf="Config.Filters[0].filterValue == 'Once'"
              >
                <app-date-time-picker
                  name="To Date"
                  placeholder="00/00/0000"
                  [disabledDates]="disabledDates"
                  [initialValue]="initialDate"
                  formControlName="ServiceCancelationToDate"
                  [disable]="!disabledService"
                ></app-date-time-picker>
              </div>
              <div
                class="col-lg-12 separate"
                *ngIf="Config.Filters[0].filterValue == 'Once' && !isAdd"
              ></div>

              <div
                Id="selector"
                *ngIf="
                  Config.Filters[2].filterVisability &&
                  Config.Filters[0].filterValue != 'Once'
                "
                class="col-lg-4"
              >
                <!-- Basic Select with Search -->
                <div style="margin-bottom: 5px">Recurrence Day</div>

                <ng-select
                  appendTo="body"
                  [(ngModel)]="Config.Filters[2].filterValue"
                  [clearable]="Config.Filters[2].filterClear"
                  [disabled]="Config.Filters[2].filterDisabled"
                  [multiple]="Config.filterMultiple"
                  [disabledd]="PermessionValue"
                  [placeholder]="Config.Filters[2].filterPlaceholder"
                  formControlName="ServiceCancelationRecurrenceDay"
                  (change)="SelectedDay()"
                  (click)="SelectedDay()"
                >
                  <ng-option
                    *ngFor="let item of Config.Filters[2].filterValues"
                    [value]="item.id"
                    >{{ item.name }}</ng-option
                  >
                </ng-select>
                <div class="dll-invalid-feedback">
                  <div
                    *ngIf="validSlectorDay"
                    class="invalid-feedback"
                    style="font-size: 10px !important"
                    [ngClass]="{ 'd-block': validSlectorDay }"
                  >
                    <div *ngIf="validSlectorDay">
                      This field is required, please select a value.
                    </div>
                  </div>
                </div>
                <!--/ Basic Select with Search -->
              </div>
              <div
                *ngIf="Config.Filters[1].filterVisability"
                class="col-lg-4"
              >
                <!-- Basic Select with Search -->
                <div
                  Id="selector"
                  style="margin-bottom: 5px"
                >
                  Apply To
                </div>
                <ng-select
                  appendTo="body"
                  [(ngModel)]="Config.Filters[1].filterValue"
                  [clearable]="Config.Filters[1].filterClear"
                  [disabled]="Config.Filters[1].filterDisabled"
                  [disabledd]="PermessionValue"
                  [multiple]="Config.filterMultiple"
                  [placeholder]="Config.Filters[1].filterPlaceholder"
                  formControlName="ApplyTo"
                  (change)="SelectedType()"
                  (click)="SelectedType()"
                >
                  <ng-option
                    *ngFor="let item of Config.Filters[1].filterValues"
                    [value]="item.id"
                    >{{ item.name }}</ng-option
                  >
                </ng-select>
                <div class="dll-invalid-feedback">
                  <div
                    *ngIf="validSlectorType"
                    class="invalid-feedback"
                    style="font-size: 10px !important"
                    [ngClass]="{ 'd-block': validSlectorType }"
                  >
                    <div *ngIf="validSlectorType">
                      This field is required, please select a value.
                    </div>
                  </div>
                </div>
                <!--/ Basic Select with Search -->
              </div>
              <div class="col-lg-12 separate"></div>
            </div>
            <div
              class="row"
              style="display: flex; padding-left: 15px; padding-right: 15px"
            >
              <div class="col-lg-8 app-type-head-c-w">
                <label>Reason</label>
                <app-type-head
                  [states]="reasons"
                  formControlName="ServiceCancelationNote"
                  placeholder="Select or enter a reason."
                  [disabledd]="PermessionValue"
                ></app-type-head>
              </div>
            </div>
            <div
              class="row"
              style="display: flex; padding-left: 15px; padding-right: 15px"
              *ngIf="!isAdd"
            >
              <div class="col-lg-12 separate"></div>
              <div class="col-lg-6">
                <button
                  type="button"
                  (click)="previewServiceCanclationItems()"
                  rippleeffect=""
                  class="btn btn-close waves-effect waves-float waves-light"
                  style="border-color: #1877f2; color: #1877f2"
                >
                  Preview Cancelations to Save
                </button>
              </div>
            </div>
            <div
              class="row"
              *ngIf="gridData.length != 0"
              style="display: flex; padding-left: 15px; padding-right: 15px"
            >
              <kendo-grid
                #UpcomingGrid
                [data]="gridView"
                [scrollable]="false"
                [skip]="state.skip"
                [pageSize]="state.take"
                [pageable]="true"
                (pageChange)="pageChange($event)"
              >
                <ng-template kendoGridNoRecordsTemplate>
                  No upcoming service cancelations.
                </ng-template>
                <ng-template
                  kendoGridToolbarTemplate
                  NgClass="p-0"
                >
                </ng-template>
                <kendo-grid-spacer></kendo-grid-spacer>
                <kendo-grid-column
                  [width]="50"
                  field="Index"
                  [sortable]="false"
                  title="#"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-rowIndex="rowIndex+1"
                  >
                    {{ rowIndex }}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                  field="EffectiveDate"
                  title="Effective Date"
                  [sortable]="false"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex"
                  >
                    {{ getEffectiveDate(dataItem) }}
                  </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                  field="ApplyTo"
                  title="Apply To"
                  [sortable]="false"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex"
                  >
                    {{ getApplyToData(dataItem.ApplyTo) }}
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                  field="Reason"
                  title="Reason"
                  [sortable]="false"
                  [width]="250"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex"
                  >
                    <span>
                      {{ dataItem.InternalNote }}
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                  field="Action"
                  title=""
                  [sortable]="false"
                >
                  <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-rowIndex="rowIndex"
                  >
                    <button
                      (click)="deleteServiceCancelationRow($event, rowIndex)"
                      class="btn btn-outline-danger btn-sm mr-2 mb-2"
                    >
                      Remove
                    </button>
                  </ng-template>
                </kendo-grid-column>
                <ng-template kendoPagerTemplate>
                  <kendo-grid-spacer></kendo-grid-spacer>
                  <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                  <kendo-pager-numeric-buttons
                    [buttonCount]="3"
                  ></kendo-pager-numeric-buttons>
                  <kendo-pager-next-buttons></kendo-pager-next-buttons>
                  <kendo-pager-info></kendo-pager-info>
                </ng-template>
              </kendo-grid>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer about-adroit-footer">
    <button
      type="button"
      (click)="close(modal)"
      rippleeffect=""
      class="btn btn-close waves-effect waves-float waves-light"
      style="border-color: #1877f2; margin-bottom: 20px; color: #1877f2"
    >
      Close
    </button>
    <button
      *ngIf="isAdd"
      type="button"
      class="btn btn-info"
      (click)="onSubmit(modal, $event)"
      style="margin-bottom: 20px; margin-right: 20px"
      [hidden]="disable"
      rippleEffect
    >
      Submit
    </button>
    <button
      *ngIf="!isAdd"
      type="button"
      class="btn btn-info"
      [disabled]="gridData.length == 0"
      (click)="onSubmit(modal, $event)"
      style="margin-bottom: 20px; margin-right: 20px"
      [hidden]="disable"
      rippleEffect
    >
      Submit
    </button>
  </div>
</ng-template>
